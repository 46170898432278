/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import Papa from 'papaparse';
import { Column, Row, Filter } from '@devexpress/dx-react-grid';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { formatCurrency } from 'utils/helpers';
import { monthNames } from 'pages/ReportsDetails/LVOWN Key2Key Report/constants';

function convertDataFieldNames(data: Row[], columns: Column[]) {
  const nameToTitleMap = columns.reduce((acc, column) => {
    (acc as any)[column.name] = column.title;
    return acc;
  }, {});

  const convertedData = data.map((item) => {
    return Object.keys(item).reduce((newItem, key) => {
      if (Object.prototype.hasOwnProperty.call(nameToTitleMap, key)) {
        const title = (nameToTitleMap as any)[key];
        (newItem as any)[title] = item[key];
      }
      return newItem;
    }, {});
  });
  return convertedData;
}

export const convertDataForCSV = (
  rows: Row[],
  columns: Column[],
  valuesConverter?: (rows: Row[]) => Row[]
) => {
  let tableData = rows;
  if (valuesConverter) tableData = valuesConverter(rows);
  return convertDataFieldNames(tableData, columns);
};

export const exportToCSV = (
  filters: Filter[] | undefined,
  rows: Row[],
  columns: Column[],
  title: string,
  valuesConverter?: (rows: Row[]) => Row[], 
  currencyColumnsName?: string[]
) => {
  const filteredRows = rows.filter((row: Row) => {
    return filters?.every(filter => {
      const { columnName, value } = filter;
      if(row[columnName] !== null) {
        if(!isNaN(filter.value) && !isNaN(row[columnName])) {
          return Number(row[columnName]).toFixed(2).toString().toLowerCase().includes(value.toString().toLowerCase());  
        }
        else {
          if(currencyColumnsName?.includes(columnName)) {
            row[columnName] = formatCurrency(row[columnName]);
          }   
          if(columnName === 'month'){
            const monthName = monthNames[row[columnName] - 1];
            return monthName.toString().toLowerCase().includes(value.toString().toLowerCase());
          }       
          return row[columnName].toString().toLowerCase().includes(value.toString().toLowerCase());
        }
      }  
    }); 
  });
  const reportData = convertDataForCSV((filters === undefined && filteredRows.length === 0) ? rows : filteredRows, columns, valuesConverter);
  const csv = Papa.unparse(reportData);
  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  const fileName = `${title + dayjs().format('DDMMYYYY')}.csv`;
  saveAs(csvBlob, fileName);
};