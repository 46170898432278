import { theme } from 'core/Theming/theme';
import { Cell as ExcelCell } from 'exceljs';
import {
    Column,
    Row,
  } from '@devexpress/dx-react-grid';
import { SLAPerformance } from 'api/resources/models/AutoGenerated';
import { dateColumnName } from '../constants';
import dayjs from 'dayjs';
import { formatDateTime } from 'utils/helpers';
import { getGroupSummaryData } from './getGroupSummaryData';
  
export const customCellFormatter = (
    cell: ExcelCell,
    row: Row,
    column: Column,
    reportData: SLAPerformance[]
  ) => {
    if (dateColumnName?.includes(column.name))
        cell.value = dayjs(row[column.name]).isValid()
          ? formatDateTime(row[column.name])
          : '';
  
      const { workbook } = cell;
      const workSheet = workbook.getWorksheet('Main');
      let color = theme.palette.primary.light?.replace('#', '');
      if (row.slaBreach === 0) {
        color = theme.palette.reports.green?.replace('#', '');
      }
      if (row.slaBreach === 1) {
        color = theme.palette.reports.pink?.replace('#', '');
      }
  
      const data = reportData;
      let groupData;
      let mainValue;
  
      if (row.groupedBy === 'slaTitle') {
        const steps = row.compoundKey.split('|');
        groupData = data?.filter((a) => a.slaTitle === steps[0]);
        mainValue = {
          richText: [
            {
              font: {
                bold: true,
              },
              text: `SLA Title: ${row.value}\r\n `,
            },
            {
              font: {
                bold: false,
                italic: true,
              },
              text: `${reportData?.find((a) => a.slaTitle === row.value)?.slaDescription
                }`,
            },
          ],
        };
      }
  
      if (row.groupedBy === 'repairerGroup') {
        const steps = row.compoundKey.split('|');
        mainValue = row.value;
        groupData = data
          ?.filter((a) => a.slaTitle === steps[0])
          .filter((a) => a.repairerGroup === steps[1]);
      }
  
      if (row.groupedBy === 'repairerName') {
        const steps = row.compoundKey.split('|');
        mainValue = row.value;
        groupData = data
          ?.filter((a) => a.slaTitle === steps[0])
          .filter((a) => a.repairerGroup === steps[1])
          .filter((a) => a.repairerName === steps[2]);
      }
  
      if (
        row.groupedBy === 'repairerName' ||
        row.groupedBy === 'repairerGroup' ||
        row.groupedBy === 'slaTitle'
      ) {
        if (groupData) {
          const result = getGroupSummaryData(groupData);
          const generalStyles = {
            font: { bold: true },
            alignment: {
              horizontal: 'center',
              wrapText: true,
              vertical: 'middle',
            },
          };
          workSheet.unMergeCells(Number(cell.row), 1, Number(cell.row), 8);
          if (row.groupedBy === 'slaTitle') {
            workSheet.mergeCells(Number(cell.row), 1, Number(cell.row), 2);
          }
          Object.assign(workSheet.getRow(Number(cell.row)).getCell(1), {
            font: { bold: true },
            alignment: {
              horizontal: 'center',
              wrapText: true,
              vertical: 'middle',
            },
          });
  
          Object.assign(
            workSheet.getRow(Number(cell.row)).getCell(7),
            generalStyles
          );
          Object.assign(
            workSheet.getRow(Number(cell.row)).getCell(5),
            generalStyles
          );
          Object.assign(
            workSheet.getRow(Number(cell.row)).getCell(3),
            generalStyles
          );
          Object.assign(workSheet.getRow(Number(cell.row)).getCell(4), {
            ...generalStyles,
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: result.color },
            },
          });
          workSheet.getColumn(Number(7)).width = 16;
  
          workSheet.getRow(Number(cell.row)).getCell(1).value = mainValue;
  
          workSheet.getRow(Number(cell.row)).getCell(3).value = result.claims;
          workSheet.getRow(Number(cell.row)).getCell(4).value =
            result.slaCompliance;
          if (result.percentages.display)
            workSheet
              .getRow(Number(cell.row))
              .getCell(
                5
              ).value = `${result.percentages.onTime}% On Time \n ${result.percentages.early}% Early \n ${result.percentages.late}% Late \n ${result.percentages.notSet}% Not Set`;
          workSheet.getRow(Number(cell.row)).getCell(7).value =
            result.averageDuration;
        }
      }
  
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      };
      cell.value = cell.value && cell.value != 'NULL' ? cell.value : '';
  };
  